export enum UploadStatusEnum {
  Init,
  Uploading,
  Succeed,
  Failed,
}

export enum ErrorCodeEnum {
  None = 'none',
  Size = 'size',
  MimeType = 'mimeType',
  Generic = 'generic',
}
