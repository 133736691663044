import { AxiosError } from 'axios';
import { ErrorCodeEnum } from './types';

export const getErrorCode = (response: AxiosError): ErrorCodeEnum => {
  const status = (response.response && response.response.status) || null;

  switch (status) {
    case 413:
      return ErrorCodeEnum.Size;
    case 415:
      return ErrorCodeEnum.MimeType;
    default:
      return ErrorCodeEnum.Generic;
  }
};
